





































import { Component, Vue } from 'vue-property-decorator'
import { TextBoxPlugin,FormValidator  } from '@syncfusion/ej2-vue-inputs';
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { NumericTextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { Enums } from '@/Helper/Enum';
import { WebApi } from '@/Helper/WebApi';
import { from } from "linq-to-typescript"
import { Session,Varient } from '@/Models/Session';
Vue.use(ButtonPlugin);
Vue.use(TextBoxPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(NumericTextBoxPlugin)
@Component
export default class AddCode extends Vue {
  Discounttype = Enums.DiscountType;
  Session:Array<Session> =[];
  SessionMaster:Array<Session> =[];
  varient :Array<Varient> = [];
  minValDate = new Date();
   data={};
   mounted(){
     console.log(this.data);
   }
  beforeMount(){
     var t = WebApi.GetSessionListPartner();
     t.then((d)=>{
         this.SessionMaster = d;
         this.$store.commit("ChangeLoading", false);
     })
  }
  ChangeSession(args:any){
     var t = from(this.Session).where(i=>i.id==args.value).first().varients;
     this.varient = t;
     console.log(this.varient);
  }
  ChangeType(args:any){
     // 1 fix, 0 flex
     console.log(this.SessionMaster);
     if(args.value == 1){
        console.log("Calling... 1");
        var s = from(this.SessionMaster).where(i=>i.allFix).toArray();
       this.Session = s;
     }
     else{
          console.log("Calling... 1 else");
        this.Session = this.SessionMaster;
     }
     console.log(this.Session);
  }
}
